
import {
  formatDate,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import {
  CashbackConfigEntities,
  CashbackConfigListData,
  CashbackListRequest,
} from "@/domain/entities/CashbackConfiguration";
import { Pagination } from "@/domain/entities/Pagination";
import { Vue } from "vue-class-component";
import { CashbackConfigurationController } from "@/app/ui/controllers/CashbackConfigController";
import moment from "moment";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";

export default class Cashback extends Vue {
  mounted() {
    this.fetchCashbackList();
  }

  cashbackList = new CashbackConfigEntities(new Pagination(1, 20), []);

  get ableToDetail() {
    return FlagsPermissionTopUpAndCashback.permission_finance_cashback_enable;
  }
  goToDetail(item: CashbackConfigListData) {
    // function to detail page
    this.$router.push(`/finance/top-up-and-cashback/cashback/${item.ccbId}`);
  }
  goToCreate() {
    // function to create page
    this.$router.push("/finance/top-up-and-cashback/cashback/create");
  }

  // loading
  isLoading = false;
  // error cause
  errorCause = "";
  isError = false;

  async fetchCashbackList() {
    // hit endpoint get list clearance
    try {
      this.isLoading = true;
      this.isError = false;
      this.errorCause = "";
      const res = await CashbackConfigurationController.GetListCashbackConfig(
        new CashbackListRequest({
          page: this.pagination.page,
          limit: this.pagination.limit,
        })
      );
      this.onMappingcashbackConfigList(res);
    } catch (error) {
      this.isError = true;
      this.errorCause = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
      this.isError = false;
    }
  }

  onMappingcashbackConfigList(data: CashbackConfigEntities) {
    this.cashbackList = data;
  }

  get pagination() {
    return this.cashbackList.pagination;
  }

  convertPeriodeCashback(val: any) {
    return moment(val).format("DD MMMM YYYY");
  }

  columns = [
    {
      name: "No.",
      styleHead: "w-10 text-left whitespace-no-wrap",
      render: (item: any, index: any) => {
        return `<div class="overflow-ellipsis text-left">${
          index + 1 + this.pagination.limit * (this.pagination.page - 1)
        }</div>`;
      },
    },
    {
      name: "Tipe Akun",
      styleHead: "w-20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left capitalize">${item.ccbAccountType}</div>`;
      },
    },
    {
      name: "Periode Cashback",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${this.convertPeriodeCashback(
          item.ccbStartDate
        )} - ${this.convertPeriodeCashback(item.ccbEndDate)}</div>`;
      },
    },
    {
      name: "Status",
      styleHead: "w-20 text-left whitespace-no-wrap",
      render: (item: any) => ChipsStyles({ status: item.ccbStatus }),
    },
    {
      name: "Terakhir Diubah",
      styleHead: "w-32 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.ccbUpdatedAt
        )}</div>
            <div class="capitalize text-gray-lp-500 text-12px">${
              item.ccbUpdatedBy
            }</div>`;
      },
    },
  ];
}
